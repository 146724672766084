.Grid {

    .TableHeader {
        display:flex;

        .TableHeaderCell {
            text-align: center;
            background-color:darkgray;
            color: white;
            flex: 1 0 0;
            padding:4px;
        }
    }
    
    .TableBody {

        .TableRow {
            display: flex;
            width:100%;
            border-bottom:1px solid lightgray;

            .TableCell {
                text-align: center;
                flex: 1 0 0;
                padding:4px;    
            }
        }
    }
}

.Flex5 {
    flex:5% 1 0px;
}
.Flex10 {
    flex:10% 1 0px;
}
.Flex15 {
    flex:15% 1 0px;
}
.Flex20 {
    flex:20% 1 0px;
}
.Flex25 {
    flex:25% 1 0px;
}
.Flex30 {
    flex:30% 1 0px;
}
.Flex35 {
    flex:35% 1 0px;
}
.Flex40 {
    flex:40% 1 0px;
}
.Flex45 {
    flex:45% 1 0px;
}
.Flex50 {
    flex:50% 1 0px;
}
.Flex55 {
    flex:55% 1 0px;
}
.Flex60 {
    flex:60% 1 0px;
}
.Flex65 {
    flex:65% 1 0px;
}
.Flex70 {
    flex:70% 1 0px;
}
.Flex75 {
    flex:75% 1 0px;
}
.Flex80 {
    flex:80% 1 0px;
}
.Flex85 {
    flex:90% 1 0px;
}
.Flex90 {
    flex:90% 1 0px;
}
.Flex95 {
    flex:95% 1 0px;
}
.Flex100 {
    flex:100% 1 0px;
}