.MortgageCalculator {
    display: flex;
    flex-direction: column;
    max-width:500px;
    margin:auto;
}

.ResultBlock {
    background: #e6e6e6;
    padding:20px;
    margin:20px;
    text-align: center;
}

.ResultBlockSecondary {
    display: flex;
    flex-wrap: wrap;
}

.Form {
    width:100%;
    display: flex;
    flex-wrap: wrap;
}

.FormControl {
    padding:6px 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 55% 1 0px;
}

.FormControl label {
    text-transform: uppercase;
    font-size:12px;
}

.FormControl input {
    padding:8px;
    border-radius:3px;
    border:1px solid gray;
    font-size:14px;
    margin-bottom:4px;
    box-sizing: border-box;
    width:100%;
}

.CalculatorActions {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding:10px 20px;
    width:100%;
}

.Button {
    padding:12px;
    font-size:14px;
    color:white;
    width:100%;
    background-color:rgba(0.8, 0.2, 0.5, 1);
    box-sizing: border-box;
    text-transform: uppercase;
    cursor:pointer;
    margin-bottom:8px;
}

.Button:hover {
    background: rgba(0.8, 0.2, 0.5, 0.8);
}

.Button:disabled {
    background: rgba(0.8, 0.2, 0.5, 0.4);
    cursor: default;
}

.ButtonSecondary {
    padding:12px;
    font-size:14px;
    color:rgba(0.8, 0.2, 0.5, 1);
    width:100%;
    border:1px solid rgba(0.8, 0.2, 0.5, 1);
    box-sizing: border-box;
    text-transform: uppercase;
    cursor:pointer;
    margin-bottom:8px;
}

.ButtonSecondary:hover {
    background: rgba(0.8, 0.2, 0.5, 0.8);
    color:white;
}

.ButtonSecondary:disabled {
    background: rgba(0.8, 0.2, 0.5, 0.4);
    cursor: default;
}

.AmortizationSchedule {
    // padding:20px;
}

@media(min-width:500px){
    .FormControl {
        flex: 100% 1 0px;    
    }
}