.chart-text {
    font: 8px/2em "Montserrat", Arial, sans-serif;
    fill: #000;
    -moz-transform: translateY(0.25em);
    -ms-transform: translateY(0.25em);
    -webkit-transform: translateY(0.25em);
    transform: translateY(0.25em);
  }
  
  .chart-number {
    font-size: 0.6em;
    line-height: 1;
    text-anchor: middle;
    -moz-transform: translateY(-0.25em);
    -ms-transform: translateY(-0.25em);
    -webkit-transform: translateY(-0.25em);
    transform: translateY(-0.25em);
  }
  
  .chart-label {
    font-size: 0.2em;
    text-transform: uppercase;
    text-anchor: middle;
    -moz-transform: translateY(0.7em);
    -ms-transform: translateY(0.7em);
    -webkit-transform: translateY(0.7em);
    transform: translateY(0.7em);
  }

  figure {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin:0;
  }

  @media (min-width: 500px) {
    figure {
      flex-direction: row;
    }
  }
  .figure-content,
  .figure-key {
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    align-self: center;
  }
  .figure-content svg {
    height: auto;
  }
  .figure-key {
    min-width: calc(8 / 12);
  }
  .figure-key [class*="shape-"] {
    margin-right: 6px;
  }
  .figure-key-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap:wrap;
  }
  .figure-key-list li {
    margin: 0 0 8px;
    padding: 0;
    font-size:14px;
    text-align: left;
    flex:45% 1 0;
  }

  .shape-circle {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
  }
  .shape-fuschia {
    background-color: #ce4b99;
  }
  .shape-lemon-lime {
    background-color: #b1c94e;
  }
  .shape-blue {
    background-color: #377bbc;
  }
  .shape-orange {
      background-color: #f5bd06;
  }
  .shape-yellow {
      background-color: #343565
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  @media(min-width:500px){

    .figure-key-list li{
        flex:100% 1 0;
    }
  }