.AutoCalculator {
    display: flex;
    flex-direction: column;
}

.ResultBlock {
    background: gray;
    padding:20px;
    margin:20px;
    text-align: center;
}

.Form {
    width:100%;
}

.FormControl {
    padding:10px 20px;
    display: flex;
    flex-direction: column;
}

.FormControl label {
    text-transform: uppercase;
    font-size:12px;
}

.FormControl input {
    padding:8px;
    border-radius:3px;
    border:1px solid gray;
    font-size:14px;
    margin-bottom:4px;
    box-sizing: border-box;
    width:100%;
}

.CalculatorActions {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding:10px 20px;
}

.Button {
    padding:12px;
    font-size:14px;
    color:white;
    width:100%;
    background-color:rgba(0.8, 0.2, 0.5, 1);
    box-sizing: border-box;
    text-transform: uppercase;
    cursor:pointer;
}

.Button:hover {
    background: rgba(0.8, 0.2, 0.5, 0.8);
}

.Button:disabled {
    background: rgba(0.8, 0.2, 0.5, 0.4);
    cursor: default;
}

@media (min-width: 500px) {
    .AutoCalculator {
        flex-direction: row;
    }
}